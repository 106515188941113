import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Api
import getServiceItemByIdApi from '../../api/getByID.api.door';
import updateServiceItemApi from '../../api/update.api.door';
import getCustomerByIdApi from '../../../customer/api/getByID.api.customer';
import getCustomerBranchByIdApi from '../../../customerBranch/api/getByID.api.customerBranch';
import createLabelRefApi from '../../../labelRef/api/create.api.labelRef';

import parseServerFault from '../../../api/lib/parseServerFault.lib.api';

// Alerts
import {alertify} from 'doorson-ui';

// Components
// Containers
// Routes
import serviceItemsPage from '../../pages/ServiceItemsPage/route';
import ServiceItem from '../../components/ServiceItem/ServiceItem';
import {format, getTime, parse, toDate} from 'date-fns';
import searchCustomerApi from '../../../customer/api/list.api.customer';
import listInterventionsApi from '../../../intervention/api/list.api.intervention';
import listMaintenancesApi from '../../../maintenance/api/list.api.maintenance';
import setQuery from '../../../api/lib/query.lib.api';
import newInterventionRoute from '../../../intervention/pages/NewInterventionPage/route';
import newMaintenanceRoute from '../../../maintenance/pages/NewMaintenancePage/route';
import interventionRoute from '../../../intervention/pages/InterventionPage/route';
import maintenanceRoute from '../../../maintenance/pages/MaintenancePage/route';
import QrScanModal from '../../components/QrScanModal/QrScanModal';
import createDoorAccessoryApi from '../../api/create.api.doorAccessory';
import deleteDoorAccessoryApi from '../../api/delete.api.doorAccessory';

class ServiceItemContainer extends Component {
  static propTypes = {
    id: PropTypes.string,
    history: PropTypes.object,
  };

  state = {
    scan: false,
    scanLoading: false,
    loading: false,
    saving: false,
    customerName: '',
    branchName: '',
    indoorLocation: '',
    buildingFloor: '',
    doorSerial: '',
    electronicsSerial: '',
    motorSerial: '',
    redundancy: '',
    leafInfo: '',
    product: null,
    productType: null,
    manufactureDate: null,
    installationDate: null,
    lastMaintenanceDate: null,
    warrantyMonths: null,
    images: [],
    accessories: [],
    updatingAccessories: [],

    branches: [],
    companies: [],
    manufacturers: [],

    interventionHistory: {interventions: [], loading: true},
    maintenanceHistory: {maintenances: [], loading: true},
    error: {},
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  init = async () => {
    const {id, history} = this.props;
    this.setState({loading: true});

    try {
      const serviceItem = await getServiceItemByIdApi(id, {rel: '*'});

      const customerBranch = await getCustomerBranchByIdApi(serviceItem.customerBranchId);
      const customer = await getCustomerByIdApi(customerBranch.customerId, {rel: '*'});
      const {branchName} = customerBranch;
      const {firstName, lastName, company} = customer;

      this.setState({
        loading: false,
        customer,
        customerBranch,
        branchName,
        customerName: company || `${firstName} ${lastName}`,
        serviceItem: this.spreadChildIds(serviceItem),
      });

      await this.getInterventions();
      await this.getMaintenances();
    } catch (error) {
      alertify.warning('Door or related data does not exists');
      history.replace(serviceItemsPage());
    }
  };

  spreadChildIds = (serviceItem) => {
    return serviceItem && {
      ...serviceItem,
      productTypeId: (serviceItem.productType || {}).id,
      productId: (serviceItem.product || {}).id,
      manufacturerId: ((serviceItem.product || {}).manufacturer || {}).id,
      manufactureDateStr: (serviceItem.manufactureDate && format(toDate(serviceItem.manufactureDate), 'dd.MM.yyyy')) || '',
      installationDateStr: (serviceItem.installationDate && format(toDate(serviceItem.installationDate), 'dd.MM.yyyy')) || '',
      warrantyValidTillDateStr: (serviceItem.warrantyValidTillDate && format(toDate(serviceItem.warrantyValidTillDate), 'dd.MM.yyyy')) || '',
      lastMaintenanceDateStr: (serviceItem.lastMaintenanceDate && format(toDate(serviceItem.lastMaintenanceDate), 'dd.MM.yyyy')) || '',
    };
  }

  getInterventions = async () => {
    const {id} = this.props;
    const query = setQuery({
      status: "CLOSED",
      order: [{
        key: 'dateResolved',
        direction: 'desc',
      }],
      filter: ['doors.doorId:EQ:' + id],
      offset: 0,
      rel: '*',
      limit: 25,
    });
    try {
      const interventions = await listInterventionsApi(query);
      this.setState({interventionHistory: {interventions, loading: false}})
    } catch (error) {
      this.setState({interventionHistory: {interventions: [], loading: false}})
      alertify.warning('Unable to load interventions');
    }
  }

  getMaintenances = async () => {
    const {id} = this.props;
    const query = setQuery({
      status: "CLOSED",
      order: [{
        key: 'dateResolved',
        direction: 'desc',
      }],
      filter: ['doors.doorId:EQ:' + id],
      offset: 0,
      rel: '*',
      limit: 25,
    });
    try {
      const maintenances = await listMaintenancesApi(query);
      this.setState({maintenanceHistory: {maintenances, loading: false}})
    } catch (error) {
      this.setState({maintenanceHistory: {maintenances: [], loading: false}})
      alertify.warning('Unable to load maintenances');
    }
  }

  onChange = (key) => async (val) => {
    const {saving, savingBranch, serviceItem} = this.state;
    if (saving || savingBranch || !serviceItem) return;

    if (key === 'customerName')
      await this.searchCompany(val, key);

    if (key === 'branchName')
      await this.searchBranch(val);

    if (key === 'manufacturerId') {
      await this.onChange('productId')(null);
    } else if (key === 'productId') {
      await (this.onChange('productTypeId')(null));
    }

    const newServiceItem = {...this.state.serviceItem, [key]: val};
    await this.setState({serviceItem: newServiceItem});
  };

  saveServiceItem = async () => {
    const {saving, serviceItem} = this.state;

    if (saving) return;

    const error = {};
    if (!serviceItem.manufacturerId)
      error.manufacturerId = 'Should not be empty';
    if (!serviceItem.productId)
      error.productId = 'Should not be empty';
    if (
      !serviceItem.productId ||
      (!!serviceItem.productId &&
        !serviceItem.productTypeId &&
        !(this.productTypes().length === 1 && this.productTypes()[0].value === ''))
    )
      error.productTypeId = 'Select an option';
    if (!serviceItem.customerBranchId)
      error.branchName = 'Should not be empty';
    if (!serviceItem.indoorLocation)
      error.indoorLocation = 'Should not be empty';
    if (!!serviceItem.manufactureDateStr && !getTime(parse(serviceItem.manufactureDateStr, 'dd.MM.yyyy', new Date())))
      error.manufactureDateStr = 'Should be in dd.mm.yyyy format';
    if (!!serviceItem.installationDateStr && !getTime(parse(serviceItem.installationDateStr, 'dd.MM.yyyy', new Date())))
      error.installationDateStr = 'Should be in dd.mm.yyyy format';
    if (!!serviceItem.warrantyValidTillDateStr && !getTime(parse(serviceItem.warrantyValidTillDateStr, 'dd.MM.yyyy', new Date())))
      error.warrantyValidTillDateStr = 'Should be in dd.mm.yyyy format';
    if (!!serviceItem.lastMaintenanceDateStr && !getTime(parse(serviceItem.lastMaintenanceDateStr, 'dd.MM.yyyy', new Date())))
      error.lastMaintenanceDateStr = 'Should be in dd.mm.yyyy format';
    if (!!serviceItem.leafInfo && serviceItem.leafInfo.length > 16)
      error.leafInfo = 'Value is too long';

    const manufactureDate = (!!serviceItem.manufactureDateStr && getTime(parse(serviceItem.manufactureDateStr, 'dd.MM.yyyy', new Date()))) || null;
    const installationDate = (!!serviceItem.installationDateStr && getTime(parse(serviceItem.installationDateStr, 'dd.MM.yyyy', new Date()))) || null;
    const warrantyValidTillDate = (!!serviceItem.warrantyValidTillDateStr && getTime(parse(serviceItem.warrantyValidTillDateStr, 'dd.MM.yyyy', new Date()))) || null;
    const lastMaintenanceDate = (!!serviceItem.lastMaintenanceDateStr && getTime(parse(serviceItem.lastMaintenanceDateStr, 'dd.MM.yyyy', new Date()))) || null;

    if (!!Object.keys(error).length) {
      this.setState({error});
      alertify.warning('Insert all the required door info properly');
      return;
    }

    this.setState({error, saving: true});

    try {
      const {id, productTypeId, ...serviceItemToUpdate} = serviceItem;
      const newServiceItem = await updateServiceItemApi(id, {
        ...serviceItemToUpdate,
        productTypeId: productTypeId || null,
        manufactureDate,
        installationDate,
        warrantyValidTillDate,
        lastMaintenanceDate
      });
      if (!this.mounted) return;
      this.setState({saving: false, serviceItem: this.spreadChildIds(newServiceItem)});
      alertify.success('Information has been updated');
    } catch (error) {
      if (!this.mounted) return;
      this.setState({saving: false});
      alertify.error('Could not save door info');
    }
  };

  searchCompany = async (search, key) => {
    const {searchKey, treshold} = (() => {
      switch (key) {
        case 'registrationNumber':
          return {searchKey: 'registrationNumber', treshold: 10};
        default:
          return {searchKey: 'company', treshold: 3};
      }
    })();

    this.setState({searchKey: key, searchingForCustomer: true});

    if (search.trim().length < treshold)
      return this.setState({companies: [], searchingForCustomer: false});

    const lastCompanySearch = getTime(new Date());
    this.lastCompanySearch = lastCompanySearch;
    try {
      const companies = await searchCustomerApi({
        rel: 'customerBranches',
        filter: encodeURIComponent(`${searchKey}:LIKEIC:%${search}%`),
      });
      if (lastCompanySearch !== this.lastCompanySearch) return;
      const filteredCompanies = [...companies].filter(
        ({location}) => !!location
      );
      this.setState({
        companies: filteredCompanies,
        searchingForCustomer: false,
      });
    } catch (error) {
      this.setState({searchingForCustomer: false});
      // Do nothing for now
    }
  };

  searchBranch = (branch) => {
    const {customer} = this.state;
    const branches =
      !!customer && !!customer.branches
        ? [...customer.branches].filter(({branchName}) =>
          branchName.toLowerCase().includes(branch.toLowerCase())
        )
        : [];
    this.setState({branchName: branch, branches});
  };

  companies = () =>
    [...this.state.companies]
      .map(({company, id}) => ({
        value: id,
        label: company,
      }));

  branches = () =>
    [...this.state.branches].map(
      ({id, branchName, branchLocation: {address, zip, city}}) => ({
        value: id,
        label: `${branchName}, ${address}, ${zip} ${city}`,
      })
    );

  manufacturers = () =>
    [...this.props.manufacturers].map(({id, name}) => ({
      value: id,
      label: name,
    }));

  onHistoryItem = (type) => (item) => {
    if (type === 'intervention') {
      this.props.history.push(interventionRoute(item.id));
    } else if (type === 'maintenance') {
      this.props.history.push(maintenanceRoute(item.id));
    }
  }

  onNewIntervention = () =>  this.props.history.push(newInterventionRoute(this.props.id));

  onNewMaintenance = () => this.props.history.push(newMaintenanceRoute(this.props.id));

  products = () => {
    // TODO: I18n
    const {manufacturers} = this.props;
    const {serviceItem} = this.state;
    const empty = [{value: '', label: 'Select a manufacturer first'}];
    if (!serviceItem) return empty;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === serviceItem.manufacturerId
    );

    return !manufacturer
      ? empty
      : [...manufacturer.products].map(({id, name}) => ({
        value: id,
        label: name,
      }));
  };

  productTypes = () => {
    // TODO: I18n
    const {manufacturers} = this.props;
    const {serviceItem} = this.state;
    const empty = [{value: '', label: 'Select a product first'}];
    if (!serviceItem) return empty;
    const manufacturer = [...manufacturers].find(
      ({id}) => id === serviceItem.manufacturerId
    );
    if (!manufacturer) return empty;
    const product = [...manufacturer.products].find(
      ({id}) => id === serviceItem.productId
    );
    return !product
      ? empty
      : !!product.productTypes.length
        ? [...product.productTypes].map(({id, name}) => ({
          value: id,
          label: name,
        }))
        : [{value: '', label: 'Various types'}];
  };

  onCustomer = (customerId) => {

    const customer = [...this.state.companies].find(c => c.id === customerId);
    const {firstName, lastName, company} = customer || {};

    this.setState({
      customer,
      customerName: company || `${firstName} ${lastName}`,
      serviceItem: {...this.state.serviceItem, customerBranchId: null},
      customerBranch: null,
      branchName: '',
    });
  };

  onBranch = (branchId) => {
    const {customer} = this.state;
    if (!customer || !customer.branches) return;
    const branch = customer.branches.find(({id}) => `${id}` === `${branchId}`);
    if (!branch) return;
    this.setState({
      serviceItem: {...this.state.serviceItem, customerBranchId: (branch || {}).id},
      customerBranch: branch,
      branchName: branch.branchName,
    });
  };

  materialOptions = () => {
    const {materials} = this.props;
    return [...(materials || [])].map((material) => ({
      value: material.id,
      label: material.name,
    }))
}

  selectedAccessories = () => {
    const {serviceItem} = this.state;

    return [...((serviceItem || {}).accessories || [])].map(
      ({id, material}) => ({
        value: id,
        label: (!!material ? material.name : 'unknown'),
      })
    );
  };

  addAccessory = async (materialId) => {
    // TODO: I18n
    const {loading, serviceItem} = this.state;
    const {materials} = this.props;
    const material = [...materials].find(r => r.id === materialId);
    if (loading || !material)
      return;

    await this.setState({updatingAccessories: [...this.state.updatingAccessories, materialId]})
    try {
      const newAccessory = await createDoorAccessoryApi(serviceItem.id, {materialId});
      if (!this.mounted) return;
      await this.setState({
        serviceItem: {
          ...this.state.serviceItem,
          accessories: [newAccessory, ...this.state.serviceItem.accessories]
        },
        updatingAccessories: [...this.state.updatingAccessories].filter(a => a !== materialId)
      });
    } catch (error) {
      if (!this.mounted) return;
      await this.setState({updatingAccessories: [...this.state.updatingAccessories].filter(a => a !== materialId)});
      alertify.error('Something went wrong. Try again.');
    }
  };

  removeAccessory = (doorAccessoryId) => async () => {
    const {loading, serviceItem} = this.state;
    const accessory = [...serviceItem.accessories].find(r => r.id === doorAccessoryId);
    if (loading || !accessory)
      return;

    await this.setState({updatingAccessories: [...this.state.updatingAccessories, doorAccessoryId]})
    try {
      await deleteDoorAccessoryApi(serviceItem.id, doorAccessoryId);
      if (!this.mounted) return;
      await this.setState({
        serviceItem: {
          ...this.state.serviceItem,
          accessories: [...this.state.serviceItem.accessories].filter(acc => acc.id !== doorAccessoryId)
        },
        updatingAccessories: [...this.state.updatingAccessories].filter(a => a !== doorAccessoryId)
      });
    } catch (error) {
      if (!this.mounted) return;
      await this.setState({updatingAccessories: [...this.state.updatingAccessories].filter(a => a !== doorAccessoryId)});
      alertify.error('Something went wrong. Try again.');
    }

  }

  historyColumns = () => [
    {
      key: 'id',
      label: 'ID',
      sortable: false,
    },
    {
      key: 'dateResolved',
      label: 'Date Resolved',
      sortable: false,
    },
    {
      key: 'customer',
      label: 'Customer',
      span: 1.5,
      sortable: false,
    },
    {
      key: 'customerBranch',
      label: 'Customer Branch',
      span: 1.5,
      sortable: false,
    },
  ];

  scan = (scan) => this.setState({scan, scanLoading: scan && this.state.scanLoading})

  onScan = async (content) => {
    try {
      const {serviceItem} = this.state;
      if (!content || !serviceItem) return;
      this.setState({scanLoading: true})

      const friendlyId = new URL(content).pathname.split('/').filter(Boolean).pop()
      const labelRef = {
        friendlyId,
        refId: serviceItem.id,
        context: 'SERVICE_ITEM'
      }

      await createLabelRefApi(labelRef);
      alertify.info("Label created. Stick it to place to be scanned")
    } catch (error) {
      console.error(error);
      alertify.error('Unable to register QR code. ' + parseServerFault(error));
    }
    this.scan(false);
  }

  render() {
    const {
      loading,
      scan,
      scanLoading,
      saving,
      serviceItem,
      customerName,
      branchName,
      updatingAccessories,
      error,
    } = this.state;

    return (
      <Fragment>
        <ServiceItem
          loading={loading}
          scan={scan}
          saving={saving}
          serviceItem={serviceItem}
          customerName={customerName}
          branchName={branchName}

          buttonLabel="Save"
          scanLabel={scan ? "Scanning..." : ((serviceItem || {}).qrCode ? "Stick another QR" : "Scan & stick QR")}
          indoorLocationLabel="Indoor location"
          buildingFloorLabel="Floor"
          doorSerialLabel="Door serial number"
          electronicsSerialLabel="Electronics serial"
          motorSerialLabel="Motor serial"
          redundancyLabel="Redundancy"
          manufacturerLabel="Manufacturer"
          productIdLabel="Door version"
          productTypeLabel="Door type"
          manufactureDateLabel="Manufacture date"
          installationDateLabel="First installation date"
          warrantyValidTillDateLabel="Warranty valid till"
          lastMaintenanceDateLabel="Last maintenance date"
          internalRemarksLabel="Internal remarks"
          branchNameLabel="Customer branch"
          customerNameLabel="Customer"
          leafInfoLabel="Leaf information"
          noSuggestionLabel="No results"
          createInterventionButtonLabel="Create new intervention"
          createMaintenanceButtonLabel="Create new maintenance"
          onNewIntervention={this.onNewIntervention}
          onNewMaintenance={this.onNewMaintenance}

          materials={this.materialOptions()}
          addAccessory={this.addAccessory}
          accessoryLabel="Accessories"
          selectedAccessories={this.selectedAccessories()}
          removeAccessory={this.removeAccessory}
          updatingAccessories={updatingAccessories}

          customers={this.companies()}
          branches={this.branches()}
          manufacturers={this.manufacturers()}
          products={this.products()}
          productTypes={this.productTypes()}
          maintenanceHistory={this.state.maintenanceHistory}
          interventionHistory={this.state.interventionHistory}
          historyColumns={this.historyColumns()}

          error={error}
          onHistoryItem={this.onHistoryItem}
          onBranch={this.onBranch}
          onCustomer={this.onCustomer}
          onChange={this.onChange}
          toggleScan={this.scan}
          onSave={this.saveServiceItem}
        />
        <QrScanModal
          loading={scanLoading}
          onScan={this.onScan}
          onClose={this.scan.bind(this, false)}
          visible={scan}/>
      </Fragment>
    );
  }
}

export default connect((state) => ({
  users: state.user.users,
  countries: state.country.countries,
  materials: state.material.materials,
  manufacturers: state.manufacturer.manufacturers,
  reportedFaults: state.reportedFault.reportedFaults,
}))(withRouter(ServiceItemContainer));
