import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  AsyncInput,
  Button,
  CheckBox,
  Column,
  Container,
  EditableOption,
  Input,
  Row,
  Select,
  Textarea,
} from 'doorson-ui';
import ServiceItemLoader from '../ServiceItemLoader/ServiceItemLoader';
import Content from './components/Content';

// Libs
import validateSerialNumberInput from "../../../layout/lib/validateSerialNumberInput.lib.layout";
import DataBlock from "../../../layout/components/DataBlock/DataBlock";
import Right from "../../../interventionDoor/components/Workinglog/components/Right";
import ButtonContainer from "../../../interventionDoor/components/Workinglog/components/ButtonContainer";
import UploadContainer from "../../../maintenanceDoor/components/DoorInfo/components/UploadContainer";
import DoorImageContainer from "../../containers/DoorImageContainer/DoorImageContainer";
import ServiceItemHistoryList from "../ServiceItemHistoryList/ServiceItemHistoryList";
import Option from '../../../interventionDoor/components/Workinglog/components/Option';

const ServiceItem = ({
                       loading,
                       scan,
                       error,
                       serviceItem,
                       branchName,
                       customerName,
                       searchingForCustomer,

                       buttonLabel,
                       scanLabel,
                       indoorLocationLabel,
                       buildingFloorLabel,
                       doorSerialLabel,
                       electronicsSerialLabel,
                       motorSerialLabel,
                       redundancyLabel,
                       manufacturerLabel,
                       productIdLabel,
                       productTypeLabel,
                       manufactureDateLabel,
                       installationDateLabel,
                       lastMaintenanceDateLabel,
                       internalRemarksLabel,
                       warrantyValidTillDateLabel,
                       branchNameLabel,
                       customerNameLabel,
                       leafInfoLabel,
                       noSuggestionLabel,

                       branches,
                       customers,
                       manufacturers,
                       products,
                       productTypes,
                       interventionHistory,
                       maintenanceHistory,
                       historyColumns,

                       createInterventionButtonLabel,
                       createMaintenanceButtonLabel,
                       onNewIntervention,
                       onNewMaintenance,

                       materials,
                       addAccessory,
                       accessoryLabel,
                       selectedAccessories,
                       removeAccessory,
                       updatingAccessories,

                       onHistoryItem,
                       onCustomer,
                       onBranch,
                       onChange,
                       toggleScan,
                       onSave,
                     }) =>
  loading || !serviceItem ? (
    <ServiceItemLoader/>
  ) : (
    <Content>
      <Container>
        <Row>
          <Column size={2 / 3}>
            <DataBlock label={"Ownership & location information"}>
              <Row>
                <Column m={1}>
                  <Row margin>
                    <Column>
                      <AsyncInput
                        value={customerName}
                        onChange={onChange('customerName')}
                        error={error.customer}
                        disabled={loading}
                        suggestions={customers}
                        onSuggestion={onCustomer}
                        noSuggestionLabel={noSuggestionLabel}
                        displaySuggestions={() =>
                          customerName.trim().length >= 3
                        }
                        searching={searchingForCustomer}
                        required
                      >
                        {customerNameLabel}
                      </AsyncInput>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column>
                      <Input
                        value={branchName}
                        onChange={onChange('branchName')}
                        error={error.branchName}
                        disabled={loading}
                        suggestions={branches}
                        onSuggestion={onBranch}
                        noSuggestionLabel={noSuggestionLabel}
                        displaySuggestions={() => branchName.trim().length >= 3}
                        required
                      >
                        {branchNameLabel}
                      </Input>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={2 / 3}>
                      <Input
                        value={serviceItem.indoorLocation}
                        onChange={onChange('indoorLocation', true)}
                        error={error.indoorLocation}
                        disabled={loading}
                        required
                      >
                        {indoorLocationLabel}
                      </Input>
                    </Column>
                    <Column size={1 / 3}>
                      <Input
                        value={serviceItem.buildingFloor}
                        onChange={onChange('buildingFloor', true)}
                        error={error.buildingFloor}
                        disabled={loading}
                        required
                      >
                        {buildingFloorLabel}
                      </Input>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </DataBlock>
            <DataBlock label={"Manufacturer information"}>
              <Row>
                <Column m={1}>
                  <Row margin>
                    <Column size={2 / 3}>
                      <Select
                        value={serviceItem.manufacturerId}
                        options={manufacturers}
                        onChange={onChange('manufacturerId')}
                        error={error.manufacturerId}
                        disabled={loading}
                        required
                      >
                        {manufacturerLabel}
                      </Select>
                    </Column>
                    <Column size={1 / 3}>
                      {serviceItem && <ButtonContainer>
                        <Button
                          size="normal"
                          theme="blue"
                          preIcon="qrcode"
                          disabled={scan}
                          outline={true}
                          onClick={() => toggleScan(!scan)}>
                          {scanLabel}
                        </Button>
                      </ButtonContainer>
                      }
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1 / 3}>
                      <Select
                        value={serviceItem.productId}
                        options={products}
                        onChange={onChange('productId')}
                        error={error.productId}
                        disabled={loading}
                        required
                      >
                        {productIdLabel}
                      </Select>
                    </Column>
                    <Column size={1 / 3}>
                      <Select
                        value={serviceItem.productTypeId}
                        options={productTypes}
                        onChange={onChange('productTypeId')}
                        error={error.productTypeId}
                        disabled={loading}
                        required={
                          !serviceItem.productId ||
                          (!!serviceItem.productId &&
                            !(
                              productTypes.length === 1 &&
                              productTypes[0].value === ''
                            ))
                        }
                      >
                        {productTypeLabel}
                      </Select>
                    </Column>
                    <Column size={1 / 3}>
                      <CheckBox
                        selected={!!serviceItem.redundancy}
                        onSelect={onChange('redundancy', true)}
                        disabled={loading}
                        large
                      >
                        {redundancyLabel}
                      </CheckBox>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1 / 3}>
                      <Input
                        type="text"
                        value={serviceItem.doorSerial}
                        onChange={onChange('doorSerial', true)}
                        error={error.doorSerial}
                        disabled={loading}
                        onKeyDown={validateSerialNumberInput}
                        required
                      >
                        {doorSerialLabel}
                      </Input>
                    </Column>
                    <Column size={1 / 3}>
                      <Input
                        type="text"
                        value={serviceItem.electronicsSerial}
                        onChange={onChange('electronicsSerial', true)}
                        error={error.electronicsSerial}
                        onKeyDown={validateSerialNumberInput}
                        disabled={loading}
                        required
                      >
                        {electronicsSerialLabel}
                      </Input>
                    </Column>
                    <Column size={1 / 3}>
                      <Input
                        type="text"
                        value={serviceItem.motorSerial}
                        onChange={onChange('motorSerial', true)}
                        error={error.motorSerial}
                        onKeyDown={validateSerialNumberInput}
                        disabled={loading}
                        required
                      >
                        {motorSerialLabel}
                      </Input>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1 / 2}>
                      <Input
                        type="text"
                        value={serviceItem.manufactureDateStr}
                        onChange={onChange('manufactureDateStr', true)}
                        error={error.manufactureDateStr}
                        disabled={loading}>
                        {manufactureDateLabel}
                      </Input>
                    </Column>
                    <Column size={1 / 2}>
                      <Input
                        type="text"
                        value={serviceItem.installationDateStr}
                        onChange={onChange('installationDateStr', true)}
                        error={error.installationDateStr}
                        disabled={loading}>
                        {installationDateLabel}
                      </Input>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1/3}>
                      <Input
                        value={serviceItem.leafInfo}
                        onChange={onChange('leafInfo', true)}
                        error={error.leafInfo}
                        disabled={loading}
                      >
                        {leafInfoLabel}
                      </Input>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </DataBlock>
            <DataBlock label={"Service information"}>
              <Row>
                <Column m={1}>
                  <Row margin>
                    <Column size={1 / 2}>
                      <Input
                        type="text"
                        value={serviceItem.warrantyValidTillDateStr}
                        onChange={onChange('warrantyValidTillDateStr')}
                        error={error.warrantyValidTillDateStr}
                        disabled={loading}>
                        {warrantyValidTillDateLabel}
                      </Input>
                    </Column>
                    <Column size={1 / 2}>
                      <Input
                        type="text"
                        value={serviceItem.lastMaintenanceDateStr}
                        onChange={onChange('lastMaintenanceDateStr', true)}
                        error={error.lastMaintenanceDateStr}>
                        {lastMaintenanceDateLabel}
                      </Input>
                    </Column>
                  </Row>
                  <Row margin>
                    <Column size={1 / 2}>
                      <Select
                        value=""
                        options={materials}
                        onChange={addAccessory}
                        error={error.accessory}
                        disabled={loading}
                      >
                        {accessoryLabel}
                      </Select>
                      {selectedAccessories.map(
                        ({value, label}, index) => (
                          <Option key={value} noMargin={!index}>
                            <EditableOption
                              type="text"
                              value={label}
                              onRemove={removeAccessory(value)}
                              onChange={()=>{}}
                              loading={updatingAccessories.includes(value)}
                            />
                          </Option>
                        )
                      )}
                    </Column>
                    <Column size={1 / 2}>
                      <Textarea
                        required={false}
                        value={serviceItem.internalRemarks}
                        type="text"
                        minRows={4}
                        error={error.internalRemarks}
                        disabled={loading}
                        onChange={onChange('internalRemarks', true)}>
                        {internalRemarksLabel}
                      </Textarea>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </DataBlock>
          </Column>
          <Column size={1 / 3}>
            <UploadContainer>
              <DoorImageContainer canAddPictures={true} door={serviceItem} onChange={onChange}/>
            </UploadContainer>
          </Column>
        </Row>
        <Row>
          <Column>
            <Right>
              <ButtonContainer>
                <Button
                  size="big"
                  theme="orange"
                  loading={loading}
                  onClick={onSave}
                >
                  {buttonLabel}
                </Button>
              </ButtonContainer>
            </Right>
          </Column>
        </Row>
        <Row>
          <Column>
            <DataBlock label="Intervention history">
              <ServiceItemHistoryList
                columns={historyColumns}
                onItem={onHistoryItem('intervention')}
                loading={interventionHistory.loading}
                items={interventionHistory.interventions}/>
              <ButtonContainer>
                <Button
                  size="normal"
                  theme="orange"
                  outline
                  loading={loading}
                  onClick={onNewIntervention}
                >
                  {createInterventionButtonLabel}
                </Button>
              </ButtonContainer>
            </DataBlock>
            <DataBlock label="Maintenance history">
              <ServiceItemHistoryList
                columns={historyColumns}
                onItem={onHistoryItem('maintenance')}
                loading={maintenanceHistory.loading}
                items={maintenanceHistory.maintenances}/>
              <ButtonContainer>
                <Button
                  size="normal"
                  theme="orange"
                  outline
                  loading={loading}
                  onClick={onNewMaintenance}
                >
                  {createMaintenanceButtonLabel}
                </Button>
              </ButtonContainer>
            </DataBlock>
          </Column>
        </Row>
      </Container>
    </Content>
  );

ServiceItem.propTypes = {
  loading: PropTypes.bool,
  scan: PropTypes.bool,
  error: PropTypes.object,
  serviceItem: PropTypes.object,
  branchName: PropTypes.string,
  customerName: PropTypes.string,
  searchingForCustomer: PropTypes.bool,

  buttonLabel: PropTypes.string,
  scanLabel: PropTypes.string,
  indoorLocationLabel: PropTypes.string,
  buildingFloorLabel: PropTypes.string,
  doorSerialLabel: PropTypes.string,
  electronicsSerialLabel: PropTypes.string,
  motorSerialLabel: PropTypes.string,
  redundancyLabel: PropTypes.string,
  manufacturerLabel: PropTypes.string,
  productIdLabel: PropTypes.string,
  productTypeLabel: PropTypes.string,
  manufactureDateLabel: PropTypes.string,
  installationDateLabel: PropTypes.string,
  lastMaintenanceDateLabel: PropTypes.string,
  warrantyValidTillDateLabel: PropTypes.string,
  branchNameLabel: PropTypes.string,
  customerNameLabel: PropTypes.string,
  noSuggestionLabel: PropTypes.string,

  branches: PropTypes.array,
  customers: PropTypes.array,
  manufacturers: PropTypes.array,
  products: PropTypes.array,
  productTypes: PropTypes.array,
  interventionHistory: PropTypes.object,
  maintenanceHistory: PropTypes.object,
  historyColumns: PropTypes.array,

  createInterventionButtonLabel: PropTypes.string,
  createMaintenanceButtonLabel: PropTypes.string,
  onNewIntervention: PropTypes.func,
  onNewMaintenance: PropTypes.func,

  onHistoryItem: PropTypes.func,
  onCustomer: PropTypes.func,
  onBranch: PropTypes.func,
  onChange: PropTypes.func,
  toggleScan: PropTypes.func,
  onSave: PropTypes.func,
};

export default ServiceItem;
